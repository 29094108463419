<style></style>
<script>
import {FormWizard, TabContent} from "vue-form-wizard";
import {with_acrf_token} from "@/methods";

export default {
  props: ['isMobile'],
  data() {
    return {
      form: null,
      invalidToken: false,
      loading: false
    };
  },
  components: {
    FormWizard,
    TabContent,
    TwoFAuthSetupPrompt: () => import("@/components/lazy/account/2FASetupPrompt.vue")
  },
  methods: {
    reset() {
      this.form = null;
    },
    validateStep(name) {
      let refToValidate = this.$refs[name];
      return refToValidate.validate();
    },
    handleError: function (error) {
      console.error(`[ERROR] ${error}`);
      this.error = true;
    },
    async completed() {
      try{
        const acrfTokenRequest = await with_acrf_token();
        if (!acrfTokenRequest.status){
          this.$swal({
            icon: 'error',
            text: this.$t('error.server.generic.message')
          });
          throw new Error('[ERROR] Failed to request acrf token');
        }
        const payload = {
          token: this.form.token.toString(),
          acsrf_token: acrfTokenRequest.acsrf_token
        }
        const response = await fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/confirm-2fa-setup',
            {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify(payload)
            });
        if (!response.ok){
          this.invalidToken = true;
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
        this.$emit('2FAEnabled');
      }catch(error){
        this.handleError(error);
      }
    },
    mergePartialModels(model, isValid) {
      if(isValid) {
        this.form = Object.assign({}, this.form, model)
      }
    },
  }
}
</script>

<template>
    <div class="d-inline-flex">

      <button
          class="btn btn btn-primary"
          v-b-modal.setup2FA
          data-toggle="modal"
          data-target="#setup2FA"
          @click="reset()"
      >
        <i class="fad fa-key-skeleton"></i> {{ $t('account.security.2fa.setup') }}
      </button>
      <b-modal id="setup2FA" size="lg" centered :title="$t('account.security.2fa.setup')" hide-footer>
        <form-wizard
            shape="circle"
            errorColor="#f46a6a"
            color="#4285F4"
            @on-complete="completed"
            :start-index="0"
            :finish-button-text="$t('account.security.2fa.process.confirm')"
        >
          <tab-content icon="fas fa-key-skeleton" :before-change="()=>validateStep('totpPrompt')">
            <TwoFAuthSetupPrompt ref="totpPrompt" @on-validate="mergePartialModels" :isMobile="isMobile"></TwoFAuthSetupPrompt>
          </tab-content>
        </form-wizard>
        <div class="alert alert-warning" role="alert" v-if="invalidToken">
          {{ $t('account.security.2fa.errors.invalid_token') }}
        </div>
      </b-modal>
    </div>
</template>
